























































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import Talk from "talkjs";
import apiSvc from "@/services/api";

import { AttendeeDataObject, AttendeeSearchResult } from "@/types/interfaces";

import AttendeeSearchSelector from "@/components/shared/AttendeeSearchSelector.vue";

import attendeeSelectionVuexModule from "@/store/vuex-modules/attendeeSelection";
const attendeeSelectionStore = getModule(attendeeSelectionVuexModule);

export default Vue.extend({
    components: {
        AttendeeSearchSelector
    },
    props: {
        modalType: { type: String, default: "new" },
        inbox: { type: Object as () => Talk.Inbox },
        activeConversation: {
            type: Object as () => Talk.ConversationData | null,
            default: null
        },
        isActive: { type: Boolean },
        initialAttendees: {
            type: Array as () => Array<AttendeeDataObject>,
            default: () => []
        }
    },
    data() {
        return {
            conversationName: ""
        };
    },
    watch: {
        isActive() {
            if (this.isActive) {
                this.initializeAttendees();

                if (
                    this.modalType == "modify" &&
                    this.activeConversation?.subject
                ) {
                    this.conversationName = this.activeConversation.subject;
                } else {
                    this.conversationName = "";
                }
            }
        }
    },
    computed: {
        modalTitle(): string {
            return this.modalType == "modify"
                ? "Add Users to Conversation"
                : "Start New Conversation";
        },

        modalPrimaryAction(): string {
            return this.modalType == "modify"
                ? "Update Conversation"
                : "Start Conversation";
        },

        userInfo(): any {
            return this.$store.getters.userInfo;
        },

        userId(): string {
            return this.userInfo.id;
        },

        talkMeetingPrefix(): string {
            return this.$store.getters.talkPrefix;
        },

        talkUser(): Talk.User {
            return this.talkModule.talkUser;
        },

        talkModule(): any {
            return this.$store.state.talkVuexModule;
        },

        conversationCustom(): any {
            return this.activeConversation?.custom;
        },

        selectorAttendees(): Array<AttendeeDataObject | AttendeeSearchResult> {
            return attendeeSelectionStore.allAttendees;
        },

        selectedConversationParticipants(): Array<
            AttendeeDataObject | AttendeeSearchResult
        > {
            const users = this.conversationCustom?.users
                ? this.conversationCustom.users.split("|")
                : [];
            const names = this.conversationCustom?.names
                ? this.conversationCustom.names.split("|")
                : [];
            const companyNames = this.conversationCustom?.companyNames
                ? this.conversationCustom.companyNames.split("|")
                : [];

            if (
                users.length === names.length &&
                names.length === companyNames.length
            ) {
                const participants = [];

                for (let i = 0; i < users.length; i++) {
                    participants.push({
                        attendeeId: users[i],
                        name: names[i],
                        companyName: companyNames[i]
                    });
                }

                return participants;
            } else {
                return [];
            }
        },

        selectedConversationInitiator(): string {
            return this.conversationCustom?.initiator
                ? this.conversationCustom.initiator
                : "";
        },

        newConversationAttendees(): Array<
            AttendeeDataObject | AttendeeSearchResult
        > {
            return this.selectorAttendees.filter((att) => {
                const convAtt = this.selectedConversationParticipants.find(
                    (item) => item.attendeeId == att.attendeeId
                );

                return !convAtt;
            });
        },

        isModalPrimaryActionAllowed(): boolean {
            if (this.modalType === "modify") {
                return (
                    this.newConversationAttendees.length >= 1 ||
                    this.conversationName != this.activeConversation?.subject
                );
            } else {
                return this.selectorAttendees.length >= 1;
            }
        },

        pageOptions(): any {
            return this.$store.getters.getPageOptions("messageCenter");
        },

        maxConversationPartners(): number {
            return this.pageOptions.maxAttendeesToMessage
                ? this.pageOptions.maxAttendeesToMessage
                : 4;
        },

        maxSelectedConversationPartners(): number {
            return this.modalType == "new"
                ? this.maxConversationPartners - 1
                : this.maxConversationPartners;
        },

        modifyConversationParticipants(): Array<
            AttendeeDataObject | AttendeeSearchResult
        > {
            return this.modalType == "modify"
                ? this.selectedConversationParticipants
                : [];
        }
    },
    methods: {
        initializeAttendees() {
            if (this.initialAttendees != null) {
                this.initialAttendees.forEach((attendeeObj) => {
                    const selected = this.selectorAttendees.find(
                        (attendee) => attendee.attendeeId === attendeeObj.id
                    );

                    if (!selected) {
                        attendeeSelectionStore.addAttendee(attendeeObj);
                    }
                });
            }
        },
        resetModal() {
            this.conversationName = "";
            attendeeSelectionStore.resetAttendees();
            this.$emit("closeModal");
        },

        async executeModalAction(): Promise<void> {
            let conversation = null;
            if (this.modalType === "modify") {
                console.log("add users to conversation");
                conversation = await this.addUsersToConversation();
            } else {
                conversation = await this.createConversation();
            }

            this.$emit("createdConversation", conversation);
        },

        /**
         * Create a new conversation from the users in the selector component.
         */
        async createConversation(): Promise<Talk.ConversationData | false> {
            const attendees = attendeeSelectionStore.allAttendees;
            const me = {
                attendeeId: this.userId,
                name: this.userInfo.name,
                companyName: this.userInfo.companyName
            };

            const allAttendees = [me, ...attendees];
            const conversationData = await this.prepConversationData(
                allAttendees
            );

            if (conversationData.names.length) {
                const conversation = this.updateOrCreateTalkConversation(
                    conversationData,
                    this.talkUser.id
                );

                return conversation;
            } else {
                this.showErrorMessage(
                    "There was an issue creating your conversation. Please try again. If you continue to have issues, please contact the Help Desk."
                );
                return false;
            }
        },

        /**
         * Prepare the custom conversation data and Talk users for a conversation.
         */
        async prepConversationData(
            attendees: Array<AttendeeSearchResult | AttendeeDataObject>
        ) {
            const names = [] as Array<string>;
            const companyNames = [] as Array<string>;
            const talkUsers = [] as Array<Talk.User>;

            const ids = attendees.map((attendee) =>
                attendee.attendeeId ? attendee.attendeeId : ""
            );

            const attendeesFromApi = await apiSvc.getConversationParticipantData(
                ids
            );

            if (attendeesFromApi && attendeesFromApi.data) {
                const attendeesToUse = attendeesFromApi.data as Array<
                    AttendeeSearchResult
                >;
                attendeesToUse.forEach((attendee) => {
                    const talkUser = this.createTalkUser(attendee);
                    if (talkUser) {
                        talkUsers.push(talkUser);
                        // Attendee objects don't have a "name", but that's all Talk uses, so default to that if present.
                        const name = attendee.name
                            ? attendee.name
                            : `${attendee.firstName} ${attendee.lastName}`;
                        names.push(name);
                        companyNames.push(
                            attendee.companyName ? attendee.companyName : ""
                        );
                    }
                });
            }

            return {
                ids,
                names,
                companyNames,
                talkUsers
            };
        },

        /**
         * Create a Talk user from attendee data.
         */
        createTalkUser(
            attendee: AttendeeDataObject | AttendeeSearchResult
        ): Talk.User | undefined {
            if (attendee.attendeeId) {
                const isProduction = this.$store.getters.isProduction;
                const talkId = this.talkMeetingPrefix
                    ? `${this.talkMeetingPrefix}-${attendee.attendeeId}`
                    : attendee.attendeeId;
                const talkName = attendee.name
                    ? attendee.name
                    : `${attendee.firstName} ${attendee.lastName}`;
                const isMatrixEmail =
                    attendee.email &&
                    attendee.email.includes("@matrixgroup.net");
                const actualEmail = attendee.email ? attendee.email : null;
                // Only supply real user emails on production, to be sure we don't send emails on dev.
                const email = isProduction
                    ? actualEmail
                    : isMatrixEmail
                    ? actualEmail
                    : null;

                return new Talk.User({
                    id: talkId,
                    name: talkName,
                    email: email,
                    role: `${this.talkMeetingPrefix.toLowerCase()}_attendee`,
                    custom: {
                        companyName: attendee.companyName
                            ? attendee.companyName
                            : ""
                    }
                });
            }
        },

        /**
         * Create a Talk conversation, or update it. In both cases, make sure we set the custom data since by default
         * Talk doesn't give us a list of participants.
         */
        updateOrCreateTalkConversation(
            data: {
                ids: Array<string>;
                names: Array<string>;
                companyNames: Array<string>;
                talkUsers: Array<Talk.User>;
            },
            initiator: string,
            id = ""
        ): Talk.ConversationData {
            const convId = id
                ? id
                : this.generateConversationId(data.talkUsers);
            const conversation = this.talkModule.talkSession.getOrCreateConversation(
                convId
            );

            data.talkUsers.forEach((talkUser) =>
                conversation.setParticipant(talkUser)
            );

            const custom = {
                initiator: initiator,
                users: data.ids.join("|"),
                names: data.names.join("|"),
                companyNames: data.companyNames.join("|")
            };

            conversation.setAttributes({
                custom: custom,
                subject: this.conversationName
            });

            if (this.inbox) {
                this.inbox.select(conversation);
            }

            // this.setSelectedConversation(conversation);

            return conversation;
        },

        /**
         * Generate a conversation ID based on the initiator and the time.
         * TODO: find a way to improve this so there's no chance (however small) of having the same ID.
         */
        generateConversationId(attendees: Array<Talk.User>): string {
            const originator = attendees[0];
            const time = Date.now();

            return `${originator.id}-${time}`;
        },

        showErrorMessage(message: string): void {
            console.log(message);
        },

        /**
         * Add users in the selector component to the currently selected conversation.
         */
        async addUsersToConversation(): Promise<Talk.ConversationData | null> {
            if (this.activeConversation) {
                const newAttendees = this.newConversationAttendees;
                const allAttendees = [
                    ...this.selectedConversationParticipants,
                    ...newAttendees
                ];

                const conversationData = await this.prepConversationData(
                    allAttendees
                );

                if (conversationData.names.length) {
                    const conversationId = this.activeConversation.id;
                    const initiator = this.activeConversation.custom.initiator;
                    const conversation = this.updateOrCreateTalkConversation(
                        conversationData,
                        initiator,
                        conversationId
                    );

                    return conversation;
                } else {
                    this.showErrorMessage(
                        "There was an issue adding users to your conversation. Please try again. If you continue to have issues, please contact the Help Desk."
                    );
                }
            } else {
                this.showErrorMessage(
                    "You don't have a conversation selected, so you cannot add users at this time. Select a conversation and try again."
                );
            }

            return null;
        }
    }
});
