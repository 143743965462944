




/**
 * This component is largely a wrapper for PollPanel to allow passing the current session
 *   down to the PollPanel.
 *
 * We use PollPanel with a static session data in some other places which is why this layer
 *   is used.
 */
import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import {} from "@/types/interfaces";
import PollPanel from "@/components/sessions/PollPanel.vue";

import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);

export default Vue.extend({
    data() {
        return {};
    },
    components: {
        PollPanel
    },
    computed: {
        session() {
            return sessionStore.singleSession;
        }
    }
});
