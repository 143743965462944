















// Setup
import Vue from "vue";
import { getModule } from "vuex-module-decorators";

// Utility

// Types
import { ContEdQuestion, UserInfo } from "@/types/interfaces";

// Components
import * as SurveyVue from "survey-vue";
const Survey = SurveyVue.Survey;

// Vuex modules

export default Vue.extend({
    props: {
        timeSinceStart: {
            default: 0
        },
        questions: {
            required: true,
            default() {
                return [] as Array<ContEdQuestion>;
            }
        },
        sessionId: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            actualQuestions: [] as Array<ContEdQuestion>,
            activeQuestion: null as ContEdQuestion | null,
            surveyObj: null as SurveyVue.SurveyModel | null
        };
    },
    components: {
        Survey
    },
    computed: {
        timeSinceStartInMinutes(): number {
            const earlyMin = this.earlyMinutes;
            return this.timeSinceStart
                ? Math.floor(this.timeSinceStart / 60) - +earlyMin
                : 0;
        },

        isModalActive(): boolean {
            return this.activeQuestion !== null;
        },

        user(): UserInfo {
            return this.$store.getters.userInfo;
        },

        isProduction(): boolean {
            return this.$store.getters.isProduction;
        },

        earlyMinutes(): Date {
            return this.$store.state.settingsVuexModule
                .sessionEarlyEntryMinutes;
        }
    },
    watch: {
        // @Watch("timeSinceStartInMinutes")
        timeSinceStartInMinutes: function(newVal: number) {
            if (
                this.activeQuestion &&
                this.activeQuestion.actualEndTime &&
                this.activeQuestion.actualEndTime <= newVal
            ) {
                this.endSurveyUnanswered();
            }

            if (!this.activeQuestion) {
                const activeQuestion = this.actualQuestions.find((question) => {
                    return (
                        !question.isComplete &&
                        question.actualStartTime &&
                        question.actualEndTime &&
                        newVal >= question.actualStartTime &&
                        newVal < question.actualEndTime
                    );
                });

                if (activeQuestion) {
                    this.activeQuestion = activeQuestion;
                    this.initializeSurvey();
                }
            }
        },

        // @Watch("sessionId")
        sessionId: function() {
            this.setupActualQuestions();
        }
    },

    created() {
        this.setupActualQuestions();
    },

    methods: {
        setupActualQuestions() {
            if (!this.questions) {
                return;
            }

            this.actualQuestions = [];

            this.questions.forEach((question) => {
                const newQ = { ...question };

                newQ.actualStartTime =
                    newQ.minTimeAfterStart +
                    Math.floor(Math.random() * newQ.variance);
                const timeAllowed = newQ.timeAllowed ? newQ.timeAllowed : 2;
                newQ.actualEndTime = newQ.actualStartTime + timeAllowed;
                newQ.isComplete = false;

                if (newQ.actualEndTime <= this.timeSinceStartInMinutes) {
                    newQ.isComplete = true;
                }

                this.actualQuestions.push(newQ);
            });
        },

        initializeSurvey() {
            if (this.activeQuestion) {
                const modelInput = {
                    pages: [
                        {
                            name: "page1",
                            elements: [this.activeQuestion.question]
                        }
                    ]
                };
                this.surveyObj = new SurveyVue.Model(modelInput);
                this.surveyObj.onComplete.add(this.completeSurvey);
            }
        },

        deactivateSurvey() {
            if (this.surveyObj) {
                this.surveyObj.clear(true, true);
                this.surveyObj.render();

                this.surveyObj = null;
            }
        },

        // TODO: Create or discover interface for SurveyJS options to get rid of any type
        completeSurvey(result: SurveyVue.SurveyModel, options: any): any {
            if (this.activeQuestion) {
                const logData = {
                    type: "ContinuingEdQuestion",
                    sessionId: this.sessionId,
                    questionId: this.activeQuestion.id,
                    questionIncomplete: false,
                    answers: JSON.stringify(result.data)
                };

                this.$store
                    .dispatch("appendLogEntry", logData)
                    .catch((err) => console.log(err));

                this.activeQuestion.isComplete = true;
                this.activeQuestion = null;
            }
        },

        endSurveyUnanswered() {
            if (this.activeQuestion) {
                const logData = {
                    type: "ContinuingEdQuestion",
                    sessionId: this.sessionId,
                    questionId: this.activeQuestion.id,
                    questionIncomplete: true
                };

                this.$store
                    .dispatch("appendLogEntry", logData)
                    .catch((err) => console.log(err));

                this.activeQuestion.isComplete = true;
                this.activeQuestion = null;
            }
        }
    }
});
