













































































































































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import { SessionComment } from "@/types/interfaces";
import apiSvc from "@/services/api";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";

import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import Spinners from "@/components/utilities/Spinners.vue";

import attendeeVuexModule from "@/store/vuex-modules/attendees";
const attendeeStore = getModule(attendeeVuexModule);

import { AttendeeDataObject } from "@/types/interfaces";

export default Vue.extend({
    components: {
        AttendeeCard,
        Spinners
    },
    props: {
        enableComments: { type: Boolean, default: true }
    },
    data() {
        return {
            commentIsPosting: false,
            commentsInterval: 0,
            comment: "",
            comments: [] as Array<SessionComment>,
            loading: false,
            isAttendeeModalActive: false,
            loadingAttendee: false
        };
    },
    computed: {
        cssVariables(): any {
            return this.$store.getters.cssVariables;
        },

        isoLastCommentLoaded(): string {
            const comments = this.comments;
            let returnValue = "";
            let lastItem;

            if (Array.isArray(comments)) {
                lastItem = comments[comments.length - 1];
            }

            if (lastItem && lastItem.createdTime) {
                returnValue = lastItem.createdTime;
            }

            return returnValue;
        },

        attendee(): AttendeeDataObject {
            return attendeeStore.attendee;
        }
    },

    mounted() {
        this.loadComments();
    },

    beforeDestroy() {
        this.clearCommentsInterval();
    },

    methods: {
        addReaction(emoji: string): void {
            if (emoji != "%E2%9D%A4%EF%B8%8F") {
                this.comment += String.fromCodePoint(Number(emoji));
            } else {
                this.comment += decodeURI(emoji);
            }
        },

        loadComments(): void {
            const videoId = this.$route.params.id;
            const loadFrom = this.isoLastCommentLoaded;

            this.clearCommentsInterval();

            apiSvc
                .getVideoComments(videoId, loadFrom)
                .then((res) => {
                    const newComments = [...this.comments, ...res.data];

                    this.comments = uniqWith(newComments, isEqual);
                    this.commentsInterval = window.setInterval(
                        this.loadComments,
                        5000
                    );
                })
                .catch((error) => {
                    console.error(error);

                    /**
                     * Fetch may fail for any number of reasons,
                     * we should continue fetch attempts.
                     */
                    this.commentsInterval = window.setInterval(
                        this.loadComments,
                        5000
                    );
                });
        },

        isOwnComment(comment: SessionComment): boolean {
            return Boolean(
                this.$store.getters.myBespeakeId === comment.attendeeId
            );
        },

        clearCommentsInterval(): void {
            window.clearInterval(this.commentsInterval);
        },

        addComment(): void {
            if (!this.enableComments) return;

            this.commentIsPosting = true;
            const videoId = this.$route.params.id;
            const comment = this.comment;

            this.clearCommentsInterval();

            apiSvc
                .postVideoComment(videoId, comment)
                .then(() => {
                    this.loadComments();
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.commentIsPosting = false;
                    // Only want to scrollchat after submitting a comment
                    this.scrollChat();
                });

            this.comment = "";
        },

        scrollChat(): void {
            this.$nextTick(() => {
                const chatScroll = this.$refs["chat-scroll"] as Element;
                // scroll to bottom of chat
                if (chatScroll) {
                    chatScroll.scrollTo(0, chatScroll.scrollHeight);
                }
            });
        },

        async openAttendeeCard(id: string): Promise<void> {
            this.loadingAttendee = true;
            this.isAttendeeModalActive = true;
            await attendeeStore.getAttendee({ id });
            this.loadingAttendee = false;
        },

        closeAttendeeCard(): void {
            this.isAttendeeModalActive = false;
            attendeeStore.clearAttendee();
        },

        sendLivestreamMessage(attendeeId: string): void {
            this.$emit("sendLivestreamMessage", attendeeId);
        }
    }
});
