






















































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import { Survey } from "survey-vue";
import { SurveyModel, Question } from "survey-core";
import {
    VisualizationPanel,
    VisualizationManager,
    WordCloud
} from "survey-analytics";
import { parseISO, isAfter } from "date-fns";
import {
    PollSingle,
    PollResponse,
    UserInfo,
    SiteOptions,
    SessionDataObject
} from "@/types/interfaces";
import svgArrowDown from "@/components/svg/svg-arrow-down.vue";

import Spinners from "@/components/utilities/Spinners.vue";

import pollsVuexModule from "@/store/vuex-modules/polls";
const pollsStore = getModule(pollsVuexModule);

interface PollWithSurveyObj {
    poll: PollSingle;
    survey: SurveyModel;
}

VisualizationManager.unregisterVisualizerForAll(WordCloud);

export default Vue.extend({
    props: {
        session: {
            type: Object as () => SessionDataObject
        },
        isInSidebar: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            initializedSurveys: [] as Array<SurveyModel>,
            activePollForResults: "-1",
            responsesInterval: undefined as number | undefined,
            resultsPanel: undefined as VisualizationPanel | undefined
        };
    },
    components: {
        Survey,
        svgArrowDown,
        Spinners
    },
    computed: {
        wrapperClasses(): string {
            return this.isInSidebar
                ? "xl:absolute top-0 left-0 xl:h-full w-full fix-scroll-to-bottom overflow-y-auto overscroll-contain"
                : "w-full";
        },

        polls() {
            return pollsStore.visiblePolls.sort(
                (a: PollSingle, b: PollSingle) => {
                    const aNum = parseInt(a.pollId);
                    const bNum = parseInt(b.pollId);
                    return aNum - bNum;
                }
            );
        },

        surveysWithWrapper() {
            const retArr = [] as Array<PollWithSurveyObj>;

            for (let i = 0; i < this.initializedSurveys.length; i++) {
                const obj = {
                    poll: this.polls[i],
                    survey: this.initializedSurveys[i]
                };

                retArr.push(obj);
            }

            return retArr;
        },

        myResponses(): Array<PollResponse> {
            return pollsStore.myResponses;
        },

        user(): UserInfo {
            return this.$store.getters.userInfo;
        },

        responses(): Array<PollResponse> {
            return pollsStore.singlePollResponses;
        },

        sessionId(): string {
            return this.$route.params.id;
        },

        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },

        options(): SiteOptions {
            return this.optionsStore.options;
        },

        showPollResults(): boolean {
            return Boolean(this.options.pollSettings?.showResultsInSidebar);
        }
    },
    mounted() {
        this.setup();
    },
    methods: {
        async setup() {
            if (!this.isInSidebar) {
                await pollsStore.getPollsForSession(this.session.sessionId);
            }

            await this.initializePolls();
            await this.$store.dispatch("getOptions", ["pollSettings"]);
            if (this.showPollResults) {
                this.selectFirstPoll();
                this.initializeResults();
            }
        },

        async initializePolls() {
            await pollsStore.getMyResponses(this.session.sessionId);

            this.polls.forEach((item: PollSingle) => {
                const responseForPoll = this.myResponses.find(
                    (resp: PollResponse) => resp.pollId === item.pollId
                );
                const surveyObj = new SurveyModel(item);
                const allowedTypes =
                    Array.isArray(item.attendeeTypesAllowed) &&
                    item.attendeeTypesAllowed.length
                        ? item.attendeeTypesAllowed
                        : [];

                const includeString = this.user.attendeeType || "";

                const canAccess =
                    !allowedTypes.length ||
                    allowedTypes.includes(includeString);

                const isAfterSession = isAfter(
                    window.MgServerTime,
                    parseISO(this.session.endTime ? this.session.endTime : "")
                );

                const preventResponse =
                    responseForPoll ||
                    !canAccess ||
                    (!item.allowVotingAfterSession && isAfterSession);
                surveyObj.title = item.pollId;
                surveyObj.showTitle = false;

                if (item.completedHtml) {
                    surveyObj.completedHtml = item.completedHtml;
                }

                if (
                    !responseForPoll &&
                    !item.allowVotingAfterSession &&
                    isAfterSession
                ) {
                    surveyObj.completedHtml = item.afterSessionHtml
                        ? item.afterSessionHtml
                        : "Sorry, you cannot participate in this poll after the session has ended.";
                }

                if (!responseForPoll && !canAccess) {
                    surveyObj.completedHtml = item.notAllowedHtml
                        ? item.notAllowedHtml
                        : "Sorry, you are not permitted to participate in this poll.";
                }

                // If this user has already responded to this poll, don't allow them to do so again.
                if (preventResponse) {
                    surveyObj.doComplete();
                } else {
                    surveyObj.onComplete.add(this.completeSurvey);
                }

                this.initializedSurveys.push(surveyObj);
            });
        },

        selectFirstPoll() {
            const firstPoll = this.polls.length ? this.polls[0] : false;

            if (firstPoll) {
                this.activePollForResults = firstPoll.pollId;
            }
        },

        changePoll() {
            this.clearResponsesInterval();
            this.initializeResults();
        },

        // TODO: Create or discover interface for SurveyJS options to rid of any type
        completeSurvey(result: SurveyModel, options: any): any {
            const logData = {
                type: "PollResult",
                pollId: result.title,
                sessionId: this.session.sessionId,
                sessionName: this.session.title,
                answers: JSON.stringify(result.data)
            };

            this.$store
                .dispatch("appendLogEntry", logData)
                .catch((err) => console.log(err));
        },

        async initializeResults(): Promise<void> {
            if (this.activePollForResults != "-1") {
                this.resultsPanel = undefined;
                const poll = this.polls.find(
                    (pollItem) => pollItem.pollId === this.activePollForResults
                );

                if (poll) {
                    const pollId = poll.pollId;

                    this.clearResponsesInterval();

                    await pollsStore.getSinglePollResponses({
                        sessionId: this.sessionId,
                        pollId: pollId
                    });

                    const analyticsOptions = {
                        haveCommercialLicense: true,
                        allowDynamicLayout: false,
                        allowHideQuestions: false
                    };

                    const survey = new SurveyModel(poll);
                    const questions = survey.getAllQuestions();
                    const thisPollResponses = this.responses.map(
                        (resp: PollResponse) => resp.answers
                    );

                    // HTML before questions can't be vizualized. This reduces results to just the question, so may be problematic?
                    const filteredQuestions = questions.filter((question) => {
                        if (!("html" in question)) {
                            return question;
                        }
                    });

                    const dataTables = new VisualizationPanel(
                        filteredQuestions,
                        thisPollResponses,
                        analyticsOptions
                    );

                    filteredQuestions.forEach((question: Question) => {
                        const visualizer = dataTables.getVisualizer(
                            question.name
                        );
                        const validTypes = [
                            "bar",
                            "pie",
                            "doughnut",
                            "scatter",
                            "text"
                        ];
                        const chartType =
                            poll.chartType &&
                            validTypes.includes(poll.chartType)
                                ? poll.chartType
                                : "pie";
                        /**
                         * TODO: Fix explicit any. For some reason the VisualizerBase type this variable is set as throws
                         * a property does not exist error, despite the fact that this works fine.
                         */
                        if (question.getType() != "text") {
                            (visualizer as any).setChartType(chartType);
                        }
                    });

                    this.resultsPanel = dataTables;

                    const container = document.getElementById(
                        "pollResultsPanel"
                    );

                    if (container) {
                        container.innerHTML = "";
                        this.resultsPanel.render(container);
                    }

                    this.responsesInterval = window.setInterval(
                        this.initializeResults,
                        10000
                    );
                }
            }
        },

        clearResponsesInterval(): void {
            window.clearInterval(this.responsesInterval);
        }
    }
});
