


























import Vue from "vue";
import Talk from "talkjs";

import apiSvc from "@/services/api";

import Spinners from "@/components/utilities/Spinners.vue";
import MessageCenterModal from "@/components/shared/MessageCenterModal.vue";

import { AttendeeDataObject } from "@/types/interfaces";

export default Vue.extend({
    components: {
        Spinners,
        MessageCenterModal
    },
    props: {
        messageData: Object as () => AttendeeDataObject | null
    },
    data() {
        return {
            talkInbox: null as null | Talk.Inbox,
            inboxTimeout: 0,
            conversationLoading: false,
            modalActive: false
        };
    },
    computed: {
        talkSession(): Talk.Session {
            return this.$store.state.talkVuexModule.talkSession;
        },

        talkUser(): Talk.User {
            return this.$store.state.talkVuexModule.talkUser;
        },

        talkMeetingPrefix(): string {
            return this.$store.getters.talkPrefix;
        },

        userInfo(): any {
            return this.$store.getters.userInfo;
        },

        getConversationId(): string {
            return this.$store.state.talkVuexModule.getConversationIdForUser;
        },

        messageDataArray(): null | Array<AttendeeDataObject> {
            return this.messageData != null ? [this.messageData] : null;
        }
    },
    watch: {
        messageData() {
            if (this.messageData != null) {
                this.tryOpenConversation(this.messageData);
            }
        }
    },
    mounted() {
        this.setupInbox();
    },
    methods: {
        setupInbox(): void {
            clearTimeout(this.inboxTimeout);

            if (this.talkUser && this.talkMeetingPrefix) {
                Talk.ready.then(() => {
                    const conversation = null;

                    // We've been passed data from somewhere else to start a new conversation, so do so.

                    const talkContainer = document.getElementById(
                        "talkjs-container"
                    );
                    this.talkInbox = conversation
                        ? this.talkSession.createInbox({
                              selected: conversation,
                              showFeedHeader: false
                          })
                        : this.talkSession.createInbox({
                              showFeedHeader: false
                          });
                    if (this.talkInbox) {
                        this.talkInbox.mount(talkContainer);

                        this.talkInbox.on("sendMessage", (e) => {
                            const logData = {
                                type: "SentMessage",
                                conversationId: e.conversation.id
                                    ? e.conversation.id
                                    : ""
                            };

                            this.$store
                                .dispatch("appendLogEntry", logData)
                                .catch((err) =>
                                    console.log(
                                        `Error logging user log in event: ${logData}, ${err}, ${err.stack}`
                                    )
                                );
                        });
                    }
                });
            } else if (!this.talkMeetingPrefix) {
                console.log("Talk not initialized, no meeting prefix set");
            } else {
                console.log("Talk not initialized, not loading inbox");
            }

            if (!this.talkInbox) {
                this.inboxTimeout = window.setTimeout(() => {
                    console.log("trying again to setup inbox in 2 seconds");
                    this.setupInbox();
                }, 2000);
            }
        },
        selectConversation(conversationId: string): void {
            if (conversationId && this.talkInbox) {
                this.talkInbox.select(conversationId);
            } else {
                console.error(
                    "Couldn't select conversation, no inbox or conversation ID"
                );
            }
            this.closeModal();
        },
        async tryOpenConversation(
            otherData: AttendeeDataObject
        ): Promise<void> {
            if (otherData) {
                this.conversationLoading = true;
                let conversationId = "";
                try {
                    const response = await apiSvc.getConversationFromId(
                        otherData.attendeeId ? otherData.attendeeId : ""
                    );

                    if (response.data) {
                        conversationId = response.data;
                    }
                } catch (err) {
                    console.error(err);
                }

                if (conversationId && this.talkInbox) {
                    this.talkInbox.select(conversationId);
                    this.$emit("messageSelectionDone");
                } else {
                    console.log("no conversation, create new modal");
                    console.log(otherData);
                    this.openModal();
                }

                this.conversationLoading = false;
            }
        },
        openModal(): void {
            this.modalActive = true;
        },
        closeModal(): void {
            this.modalActive = false;
            this.$emit("messageSelectionDone");
        }
    }
});
