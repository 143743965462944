




































































































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import debounce from "lodash/debounce";
import {
    AttendeeDataObject,
    AttendeeSearchResult,
    AttendeeSearchInput
} from "@/types/interfaces";
import MgImage from "@/components/shared/MgImage.vue";

import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";
const attendeeSearchStore = getModule(attendeeSearchVuexModule);

import attendeeSelectionVuexModule from "@/store/vuex-modules/attendeeSelection";
const attendeeSelectionStore = getModule(attendeeSelectionVuexModule);

type DebounceHandler = (keyword: string) => void;

/**
 * TODO:
 *   - populate attendees list with initial attendees from prop
 *   - populate immutable attendees list with initial immutable attendees from prop
 *   - more testing
 */
export default Vue.extend({
    components: {
        MgImage
    },
    props: {
        maxAttendees: { type: Number, default: -1 },
        title: { type: String, default: "Invited Attendees" },
        noAttendeesText: {
            type: String,
            default: "No attendees are currently invited."
        },
        fieldLabel: { type: String, default: "Invite More Attendees" },
        initialAttendees: {
            type: Array as () => Array<
                AttendeeSearchResult | AttendeeDataObject
            >,
            default: () => []
        },
        initialImmutableAttendees: {
            type: Array as () => Array<
                AttendeeSearchResult | AttendeeDataObject
            >,
            default: () => []
        },
        context: {
            type: String
        },
        shouldAllowSelf: {
            type: Boolean,
            default: false
        },
        disallowedAttendeeIds: {
            type: Array as () => Array<string>,
            default: () => []
        }
    },
    data() {
        return {
            isLoadingAttendees: false,
            searchSelection: null as null | AttendeeSearchResult,
            fetchAttendees: null as null | DebounceHandler
        };
    },
    computed: {
        attendeeSearchResults(): Array<AttendeeSearchResult> {
            return attendeeSearchStore.attendeeSearchResults;
        },

        filteredAttendeeSearchResults(): Array<AttendeeSearchResult> {
            const userId = this.$store.getters.userInfo.id;

            return this.attendeeSearchResults.filter((result) => {
                const selfCondition =
                    this.shouldAllowSelf || result.attendeeId != userId;

                return (
                    selfCondition && !this.allIds.includes(result.attendeeId) && !this.disallowedAttendeeIds.includes(result.attendeeId)
                );
            });
        },

        selectedAttendees(): Array<AttendeeSearchResult | AttendeeDataObject> {
            return attendeeSelectionStore.selectedAttendees;
        },

        immutableAttendees(): Array<AttendeeSearchResult | AttendeeDataObject> {
            return attendeeSelectionStore.immutableAttendees;
        },

        immutableIds(): Array<string | undefined> {
            return attendeeSelectionStore.immutableIds;
        },

        allIds(): Array<string | undefined> {
            return attendeeSelectionStore.allIds;
        },

        allAttendees(): Array<AttendeeSearchResult | AttendeeDataObject> {
            return attendeeSelectionStore.allAttendees;
        }
    },
    watch: {
        searchSelection() {
            if (this.searchSelection) {
                attendeeSelectionStore.addAttendeeToSelection(
                    this.searchSelection
                );
            }

            this.searchSelection = null;
        }
    },
    created() {
        this.setupInitialAttendees();
        this.fetchAttendees = debounce((keyword: string) => {
            const searchInput = {
                allFields: keyword
            } as AttendeeSearchInput;

            if (this.context) {
                searchInput.context = this.context;
            }

            attendeeSearchStore.searchAttendees(searchInput).finally(() => {
                // Not sure why we can't update component data here
                // so using `resetFlags` instead.
                this.resetFlags();
            });
        }, 500);
    },

    beforeDestroy() {
        attendeeSelectionStore.resetAttendees();
    },

    methods: {
        resetFlags(): void {
            this.isLoadingAttendees = false;
            console.log("reset flags");
        },

        handleTyping(keyword: string): void {
            this.isLoadingAttendees = true;
            if (this.fetchAttendees) {
                this.fetchAttendees(keyword);
            }
        },

        attendeeIsImmutable(id: string): boolean {
            return this.immutableIds.includes(id);
        },

        removeAttendee(id: string): void {
            attendeeSelectionStore.removeAttendee(id);
        },

        setupInitialAttendees(): void {
            attendeeSelectionStore.setSelected(this.initialAttendees);
            attendeeSelectionStore.setImmutableAttendees(
                this.initialImmutableAttendees
            );
        },

        getAttendeeName(
            attendee: AttendeeDataObject | AttendeeSearchResult
        ): string {
            return attendee.name
                ? attendee.name
                : `${attendee.firstName} ${attendee.lastName}`;
        },

        getAvatarSrc(
            attendee: AttendeeDataObject | AttendeeSearchResult
        ): string {
            let returnValue = "";
            const data = attendee;
            const images = data.images?.avatar;

            if (images && (images["640x640"] || images["320x320"])) {
                if (images["320x320"]) {
                    returnValue = images["320x320"];
                } else {
                    returnValue = images["640x640"];
                }
            }

            return returnValue;
        },

        getAvatarSrcSet(
            attendee: AttendeeDataObject | AttendeeSearchResult
        ): string {
            const data = attendee;
            let returnPath = "";
            const set = [];

            if (data.images?.avatar) {
                const urls = data.images.avatar;
                const imgSm = urls["320x320"];
                const imgLg = urls["640x640"];

                if (imgSm) {
                    set.push(`${imgSm} 600w`);
                }

                if (imgLg) {
                    set.push(`${imgLg} 2048w`);
                }

                returnPath = set.join(",");
            }

            return returnPath;
        }
    }
});
