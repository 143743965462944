<template>
    <section>
        <div class="py-4 xl:py-16 bg-livestream flex justify-center">
            <div
                class="flex-1 w-full max-w-xl mx-4 xl:mx-16 stream-video-wrapper xl:flex xl:shadow"
            >
                <div
                    class="vm-Player bg-black flex flex-col flex-1"
                    :style="headeroffsetHeight"
                >
                    <div class="inner m-auto w-full">
                        <template v-if="routeIsSession">
                            <div
                                v-if="loading"
                                class="aspect-ratio-16-9 bg-primary text-white"
                            >
                                <div
                                    class="absolute transform -translate-y-1/2 -translate-x-1/2 top-half left-half text-center"
                                >
                                    <strong>Thank you for joining.</strong>
                                    <div>Loading...</div>
                                </div>
                            </div>
                            <template v-else>
                                <div
                                    v-if="(loadError && !videoData.inPerson) || (!videoOptions && !videoData.inPerson) "
                                    class="aspect-ratio-16-9 bg-black text-white"
                                    key="no-video"
                                >
                                    <strong
                                        class="absolute transform -translate-y-1/2 -translate-x-1/2 top-half left-half"
                                        >Video not found.</strong
                                    >
                                </div>
                                <div
                                    v-else-if="!hasStarted && !loadError"
                                    class="aspect-ratio-16-9 bg-primary text-white"
                                >
                                    <div
                                        class="absolute transform -translate-y-1/2 -translate-x-1/2 top-half left-half text-center"
                                    >
                                        <strong>Thank you for joining.</strong>
                                        <p v-if="timeLabel" class="font-light">
                                            Session will begin in
                                            {{ timeLabel }}.
                                        </p>
                                    </div>
                                </div>
                                
                                <div v-else-if="videoData.inPerson && joinType()"
                                    class="aspect-ratio-16-9 bg-black text-white"
                                    key="no-video"
                                >
                                    <img :src="videoData.image" alt="Session Thumbnail">
                                </div>
                                <template v-else>
                                    <video-component
                                        key="has-video"
                                        :start-at="timeSinceStart"
                                        :options="videoOptions"
                                        :sessionData="session"
                                    />
                                </template>
                            </template>
                        </template>
                        <template v-else class="aspect-ratio-16-9">
                            No session data.
                        </template>
                    </div>
                </div>
                <LivestreamSidebar
                    :videoData="videoData"
                    :enableComments="enableComments"
                />
            </div>
        </div>

        <div class=" bg-white xl:flex justify-center px-4 xl:px-16">
            <div class="flex-1 w-full max-w-xl my-16 xl:pr-8">
                <h1 v-if="!routeIsSession && title" class="title is-3">
                    {{ title }}
                </h1>

                <div class="content" v-else-if="videoData">
                    <h1 v-if="videoData.title" class="title is-3">
                        {{ videoData.title }}
                    </h1>

                    <div
                        v-if="videoData.description"
                        class="content my-3 mb-8 border-b pb-8"
                    >
                        <span v-html="videoData.description"></span>
                    </div>

                    <div
                        v-if="
                            Array.isArray(videoData.speakers) &&
                                videoData.speakers.length
                        "
                        class="content my-3"
                    >
                        <h2 class="mb-6 text-primary">Speakers</h2>

                        <div
                            class="mb-6"
                            v-for="(speaker, index) in videoData.speakers"
                            :key="index"
                        >
                            <h3 class="mb-2 text-base text-primary">
                                <strong v-if="speaker.speakerRole"
                                    >{{ speaker.speakerRole }}:
                                </strong>
                                <router-link
                                    class="font-bold"
                                    :to="
                                        `/attendees/attendee-profile/${speaker.id}`
                                    "
                                    v-if="speaker.id"
                                >
                                    {{ speaker.name }}
                                </router-link>
                                <span v-else>{{ speaker.name }}</span>
                                <span
                                    v-html="formattedSpeakerText(speaker)"
                                ></span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-16 xl:w-1/3">
                <b-notification
                    v-if="showPresenceUi"
                    type="is-info"
                    :closable="false"
                    class="px-6 notification--full-media-content"
                >
                    <h2 class="title is-3">
                        <font-awesome-icon
                            aria-hidden="true"
                            class="mr-2"
                            icon="users"
                        />
                        Who's here?
                    </h2>
                    <p v-if="loadingAttendees">loading...</p>
                    <p v-if="!loadingAttendees && 1 == routePresence.length">
                        <b>{{ routePresence.length }}</b> attendee is present.
                    </p>
                    <p v-if="!loadingAttendees && 1 < routePresence.length">
                        <b>{{ routePresence.length }}</b> attendees are present.
                    </p>
                    <div
                        class="overflow-hidden bg-white shadow rounded w-full transform"
                    >
                        <div
                            class="px-4 py-2 overflow-y-auto overscroll-contain"
                        >
                            <p class="text-sm font-bold opacity-50 m-0">
                                Attendees
                            </p>
                            <Spinners
                                v-if="loadingAttendees"
                                class="h-64 flex items-center"
                            />
                            <div
                                v-show="!loadingAttendees"
                                class="divide-y divide-dividers w-full h-64"
                            >
                                <div
                                    v-for="(item,
                                    index) in presentAttendeesToDisplay"
                                    class="py-2 truncate"
                                    :key="
                                        `${item.attendeeId}-${index}-${item.timestamp}`
                                    "
                                >
                                    <span v-html="getName(item)"></span>
                                    <span
                                        v-if="item.companyName"
                                        class="text-sm opacity-50 truncate"
                                        >{{ item.companyName }}
                                    </span>
                                </div>
                                <div
                                    class="flex justify-end"
                                    v-if="moreAttendeesToLoad"
                                >
                                    <button
                                        @click="getPresentAttendees"
                                        class="button my-4"
                                    >
                                        Load more
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-notification>
            </div>
        </div>

        <continuing-ed-questions
            v-if="ceQuestions && ceQuestions.length"
            :sessionId="session.sessionId"
            :timeSinceStart="timeSinceStart"
            :questions="ceQuestions"
        />
    </section>
</template>

<script>
// Setup
import { mapGetters, mapState } from "vuex";
import { getModule } from "vuex-module-decorators";

// Utility
import differenceBy from "lodash/differenceBy";
import { subMinutes, differenceInSeconds, formatDistance } from "date-fns";
import { sessionHelpSessionIsOver } from "@/services/session-helpers";

// Components
import VideoComponent from "@/components/shared/VideoComponent.vue";
import ContinuingEdQuestions from "@/components/sessions/ContinuingEdQuestions";
import Spinners from "@/components/utilities/Spinners.vue";
import LivestreamSidebar from "@/components/sessions/LivestreamSidebar";

// Vuex modules
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);

import pollsVuexModule from "@/store/vuex-modules/polls";
const pollsStore = getModule(pollsVuexModule);

import presenceVuexModule from "@/store/vuex-modules/presence";
const presenceStore = getModule(presenceVuexModule);

import attendeeVuexModule from "@/store/vuex-modules/attendees";
const attendeeStore = getModule(attendeeVuexModule);

export default {
    name: "LiveStream",
    components: {
        VideoComponent,
        ContinuingEdQuestions,
        Spinners,
        LivestreamSidebar
    },
    data() {
        return {
            loadError: false,
            loading: false,
            loadingAttendees: false,
            title: "",
            videoData: null,
            videoOptions: null,
            sessionIsActiveInterval: null,
            isSessionOver: false,
            timeSinceStart: -1,
            timeLabel: "",
            headeroffsetHeight: "",
            activeSidebar: "comments",
            presentAttendeesToDisplay: [],
            loadSetsOfAttendees: 20,
            attendeeLoadCount: 0,
            routePresenceInterval: null
        };
    },
    computed: {
        ...mapState(["route"]),
        ...mapGetters([
            "cssVariables",
            "layoutOptions",
            "awsConfig",
            "user",
            "sponsorVideos",
            "getPageOptions",
            "isSuperUser"
        ]),
        pageOptions() {
            return this.getPageOptions("liveStream") || {};
        },
        sessionOptions() {
            return this.getPageOptions("sessions") || {};
        },
        speakerFields() {
            return this.sessionOptions.speakerFields
                ? this.sessionOptions.speakerFields
                : ["title", "companyName"];
        },
        showPresence() {
            return Boolean(this.pageOptions.showPresence);
        },
        presenceSuperUserOnly() {
            return Boolean(this.pageOptions.presenceSuperUserOnly);
        },
        showPresenceUi() {
            let returnValue = false;

            if (this.presenceSuperUserOnly) {
                returnValue = this.isSuperUser;
            } else {
                returnValue = this.showPresence;
            }

            return returnValue;
        },
        hasStarted() {
            return Boolean(this.timeSinceStart > -1);
        },
        myStartTime() {
            const returnValue = this.videoData?.startTime;
            return returnValue;
        },
        myEndTime() {
            const returnValue = this.videoData?.endTime;
            return returnValue;
        },
        isSponsorVideo() {
            let returnValue = false;

            if (this.videoData && "isSponsorVideo" in this.videoData) {
                returnValue = this.videoData.isSponsorVideo;
            }

            return returnValue;
        },
        session: {
            get() {
                return sessionStore.session;
            },
            set() {
                // n/a
            }
        },
        sessionDateTime() {
            if (!this.videoData || !this.myStartTime) {
                return undefined;
            }
            return new Date(this.myStartTime);
        },
        startTimeAdjusted() {
            const startDate = this.sessionDateTime;
            const earlyMins = this.$store.state.settingsVuexModule
                .sessionEarlyEntryMinutes;
            return subMinutes(startDate, earlyMins);
        },
        routeIsSession() {
            return Boolean("Session" === this.route.name);
        },
        enableComments() {
            return Boolean(this.routeIsSession || this.videoOptions);
        },
        sponsorVideo() {
            let returnValue = null;

            if (Array.isArray(this.sponsorVideos)) {
                returnValue = this.sponsorVideos.find(
                    (item) => this.route.params.id === item.sessionId
                );
            }

            return returnValue;
        },
        routePresence() {
            return presenceStore.routePresence;
        },
        moreAttendeesToLoad() {
            return Boolean(this.routePresence.length > this.attendeeLoadCount);
        },
        ceQuestions() {
            return this.session && Array.isArray(this.session.ceQuestions)
                ? this.session.ceQuestions
                : [];
        }
    },
    created() {
        this.videoInit();

        if (this.showPresenceUi) {
            // get intial attendee data
            this.getPresentAttendees();

            // check for difference in attendance on interval
            this.handleRoutePresenceInterval();
        }

        const eL = document.getElementsByClassName("navbar-brand")[0];
        this.headeroffsetHeight = `top:${eL.offsetHeight}px`;

    },
    beforeDestroy() {
        window.clearInterval(this.sessionIsActiveInterval);
        window.clearInterval(this.routePresenceInterval);
    },
    methods: {
        joinType(){
           const joinedAs = this.route.params.joinType;

            if(this.session.inPerson && (joinedAs == undefined || joinedAs == 'inPerson')){
                return true;
            }else{
                return false;
            }


        },
        handleActiveSessionCheck() {
            const session = this.session;
            const startDate = this.sessionDateTime;
            const adjustedStartTimeDate = this.startTimeAdjusted;

            // we dont care about `sessionHelpSessionIsActive` here,
            // we only care if the session has started (timeSinceStart) and if the session is over
            this.isSessionOver = sessionHelpSessionIsOver(session);

            this.timeLabel = formatDistance(window.MgServerTime, startDate);

            if (this.isSessionOver) {
                this.timeSinceStart = 0;
            } else {
                this.timeSinceStart = differenceInSeconds(
                    window.MgServerTime,
                    adjustedStartTimeDate
                );
            }
        },
        videoInit() {
            if (this.routeIsSession) {
                this.loading = true;
                sessionStore
                    .getSingleSession({
                        sessionId: this.route.params.id,
                        isDemoData: false
                    })
                    .then(() => {
                        this.videoData = this.session;
                        this.handleSessionLoadedActions();
                    })
                    .catch((error) => {
                        if (401 === error.response.status) {
                            this.$router.push("/401");
                        }
                        this.loadError = true;
                        this.videoData = this.sponsorVideo;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        handleSessionLoadedActions() {
            // First
            this.handleActiveSessionCheck();

            // Second
            this.handleVideoOptions();
            this.updatePolls();

            // Finally
            this.sessionIsActiveInterval = window.setInterval(() => {
                this.handleActiveSessionCheck();
            }, 1000);
        },
        handleVideoOptions() {
            const options = {
                autoplay: true,
                controls: true,
                sources: null,
                poster: "",
                controlBar: {}
            };

            if (!this.videoData) {
                return;
            } else if (
                this.videoData.videoUrl ||
                this.videoData.videoUrlOnDemand
            ) {
                if (this.videoData.image) {
                    options.poster = this.videoData.image;
                }

                if (!this.isSessionOver) {
                    options.controlBar.progressControl = false;
                    options.sources = [
                        {
                            src: this.videoData.videoUrl,
                            type: "application/x-mpegURL"
                        }
                    ];
                } else {
                    let videoToUse = this.videoData.videoUrl;

                    if (this.videoData.videoUrlOnDemand) {
                        videoToUse = this.videoData.videoUrlOnDemand;
                    }

                    options.sources = [
                        {
                            src: videoToUse,
                            type: "application/x-mpegURL"
                        }
                    ];
                }

                this.videoOptions = options;
            }
        },
        updatePolls() {
            pollsStore.setThisSessionData(this.session);
            pollsStore.getPollsForSession(this.session.sessionId);
        },
        getRoutePresence() {
            const routeIdentifier = `${this.route.name}_${this.route.params.id}`;
            presenceStore.getRoutePresence(routeIdentifier).then((newData) => {
                const currentData = this.routePresence;
                const difference = differenceBy(
                    newData,
                    currentData,
                    "attendeeId"
                );
                const differenceInNewPresenceData = Boolean(
                    difference.length || newData.length < currentData.length
                );

                if (differenceInNewPresenceData) {
                    // presence info has changed - reset data
                    this.attendeeLoadCount = 0;
                    this.presentAttendeesToDisplay = [];
                    presenceStore.setRoutePresence(newData);

                    // refresh attendee data
                    this.getPresentAttendees();
                }
            });
        },
        getPresentAttendees() {
            const sliceEnd = this.attendeeLoadCount + this.loadSetsOfAttendees;
            const slice = this.routePresence.slice(
                this.attendeeLoadCount,
                sliceEnd
            );
            const ids = slice.map((item) => item.attendeeId);

            this.loadingAttendees = true;
            attendeeStore
                .getAttendees(ids)
                .then((response) => {
                    const currentDisplayAttendees = this
                        .presentAttendeesToDisplay;

                    this.presentAttendeesToDisplay = [
                        ...currentDisplayAttendees,
                        ...response
                    ];
                })
                .finally(() => {
                    this.loadingAttendees = false;
                });

            this.attendeeLoadCount += this.loadSetsOfAttendees;
        },
        handleRoutePresenceInterval() {
            window.clearInterval(this.routePresenceInterval);
            this.routePresenceInterval = window.setInterval(
                this.getRoutePresence,
                10000
            );
        },
        getName(item = {}) {
            const names = [];
            if (item.firstName) {
                names.push(item.firstName);
            }
            if (item.lastName) {
                names.push(item.lastName);
            }

            if (item.companyName) {
                const lastItem = names.pop();
                names.push(`${lastItem},&nbsp;`);
            }
            return names.join(" ");
        },
        formattedSpeakerText(speaker) {
            const fields = this.speakerFields;

            let data = "";

            const commaFields = [];

            fields.forEach((field) => {
                if (speaker[field]) {
                    commaFields.push(`${speaker[field]}`);
                }
            });

            if (commaFields.length) {
                data += ` &ndash; ${commaFields.join(", ")}`;
            }

            return data;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../styles/views/livestream";
@import "../styles/components/chat";
</style>
