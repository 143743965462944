import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Store from "../index";
import { AttendeeDataObject, AttendeeSearchResult } from "@/types/interfaces";

@Module({
    dynamic: true,
    store: Store,
    name: "AttendeeSelection",
    namespaced: true
})
export default class AttendeeSelection extends VuexModule {
    /**
     * There are potentially two sets of attendees for any given selection: normal and immutable.
     * Obviously given the below they're not immutable in code, but the user should not be given the opportunity
     * 	 to add/remove users in the immutable array. This is mostly for loading existing selections of users where
     *   the user is not allowed to remove or modify users in that list.
     */
    selected = [] as Array<AttendeeDataObject | AttendeeSearchResult>;
    immutableSelected = [] as Array<AttendeeDataObject | AttendeeSearchResult>;

    get selectedAttendees() {
        return this.selected;
    }

    get immutableAttendees() {
        return this.immutableSelected;
    }

    get allIds() {
        return this.selected.map(
            (item: AttendeeDataObject | AttendeeSearchResult) =>
                item.attendeeId ? item.attendeeId : ""
        );
    }

    get immutableIds() {
        return this.immutableSelected.map(
            (item: AttendeeDataObject | AttendeeSearchResult) => item.attendeeId
        );
    }

    get allAttendees() {
        return [...this.selected, ...this.immutableSelected];
    }

    @Mutation
    public setSelected(
        attendees: Array<AttendeeDataObject | AttendeeSearchResult>
    ) {
        this.selected = attendees;
    }

    @Mutation
    public addAttendeeToSelection(
        attendee: AttendeeDataObject | AttendeeSearchResult
    ) {
        this.selected.push(attendee);
    }

    @Mutation
    public removeAttendeeFromSelection(id: string) {
        const itemIndex = this.selected.findIndex(
            (item) => item.attendeeId === id
        );

        if (itemIndex != -1) {
            this.selected.splice(itemIndex, 1);
        }
    }

    @Mutation
    public clearAttendeeSelection() {
        this.selected.splice(0);
    }

    @Mutation
    public setImmutable(
        attendees: Array<AttendeeDataObject | AttendeeSearchResult>
    ) {
        this.immutableSelected = attendees;
    }

    @Action({ commit: "addAttendeeToSelection" })
    public addAttendee(attendee: AttendeeDataObject | AttendeeSearchResult) {
        return attendee;
    }

    @Action({ commit: "removeAttendeeFromSelection" })
    public removeAttendee(id: string) {
        return id;
    }

    @Action({})
    public resetAttendees() {
        this.context.commit("clearAttendeeSelection");
    }

    @Action({ commit: "setSelected" })
    public setSelectedAttendees(
        attendees: Array<AttendeeDataObject | AttendeeSearchResult>
    ) {
        return attendees;
    }

    @Action({ commit: "setImmutable" })
    public setImmutableAttendees(
        attendees: Array<AttendeeDataObject | AttendeeSearchResult>
    ) {
        return attendees;
    }
}
