<template>
    <svg :fill="color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M86.9102564,31.025641 C85.8846154,30 84.2179487,30 83.1923077,31.025641 L50.5,63.7820513 L17.7435897,31.025641 C16.7179487,30 15.0512821,30 14.025641,31.025641 C13,32.0512821 13,33.7179487 14.025641,34.7435897 L48.5769231,69.2948718 C49.0897436,69.8076923 49.7307692,70.0641026 50.4358974,70.0641026 C51.0769231,70.0641026 51.7820513,69.8076923 52.2948718,69.2948718 L86.8461538,34.7435897 C87.9358974,33.7179487 87.9358974,32.0512821 86.9102564,31.025641 Z"
                :fill="color"
                fill-rule="nonzero"
            ></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-arrow-down",
    props: {
        color: { type: String, default: "#000" }
    }
};
</script>
