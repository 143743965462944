<template>
    <div
        class="xl:absolute top-0 left-0 xl:h-full w-full overflow-y-auto overscroll-contain p-4"
    >
        <Spinners v-if="loading" />
        <b-notification
            v-else-if="relatedSessionsError"
            type="is-danger"
            :closable="false"
        >
            There was an error loading the related sessions.
        </b-notification>
        <b-notification
            v-else-if="0 === relatedSessions.length"
            type="is-info"
            :closable="false"
        >
            No related sessions.
        </b-notification>

        <!-- `:isLoadingFavorites="true"` used to suppress favorting control -->
        <SessionScheduleItem
            v-else
            class="first:pt-0 py-4 border-dividers border-b last:border-0"
            v-for="(session, index) in relatedSessions"
            :key="`${session.id}-${index}`"
            :session="session"
            :isLoadingFavorites="true"
            :isRelatedSession="true"
            layout="column"
        />
    </div>
</template>

<script>
import { getModule } from "vuex-module-decorators";
import SessionScheduleItem from "@/components/sessions/SessionScheduleItem.vue";
import Spinners from "@/components/utilities/Spinners.vue";

import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);

export default {
    props: {
        session: {
            type: Object,
            required: true,
            default() {
                return {};
            }
        }
    },
    components: { Spinners, SessionScheduleItem },
    data() {
        return {
            loading: false,
            relatedSessions: [],
            relatedSessionsError: false
        };
    },
    created() {
        this.getRelatedSessions();
    },
    methods: {
        async getRelatedSessions() {
            const data = this.session;
            this.loading = true;
            try {
                this.relatedSessions = await sessionStore.getRelatedSessions(
                    data
                );
            } catch (error) {
                this.relatedSessionsError = true;
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
