




































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import SessionPolls from "@/components/sessions/SessionPolls.vue";
import SessionComments from "@/components/sessions/SessionComments.vue";
import RelatedSessions from "@/components/sessions/RelatedSessions.vue";
import LivestreamMessages from "@/components/sessions/LivestreamMessages.vue";
import InPersonSessionInformation from "@/components/sessions/InPersonSessionInformation.vue";

import {
    SessionDataObject,
    PollSingle,
    AttendeeDataObject
} from "@/types/interfaces";

import pollsVuexModule from "@/store/vuex-modules/polls";
const pollsStore = getModule(pollsVuexModule);

import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);

export default Vue.extend({
    name: "LivestreamSidebar",
    props: {
        videoData: { type: Object as () => SessionDataObject },
        enableComments: { type: Boolean }
    },
    components: {
        SessionPolls,
        SessionComments,
        RelatedSessions,
        LivestreamMessages,
        // InPersonSessionInformation
    },
    data() {
        return {
            activeSidebar: "comments",
            livestreamMessageData: null as null | AttendeeDataObject
        };
    },
    computed: {
        hasRelatedSessions(): boolean {
            return this.$store.getters.getPageOptions("liveStreamSideBar")
                .hasRelatedSessions;
        },
        hasTabs(): boolean {
            // todo: we'll implement an option to turn tabs off in case meetings dont have/require inbox messaging
            return true;
        },
        polls(): Array<PollSingle> {
            return pollsStore.visiblePolls;
        },
        hasPolls(): boolean {
            return Boolean(this.polls.length);
        },
        session(): SessionDataObject {
            return sessionStore.session;
        },
        talkUnreads(): number {
            return this.$store.getters.talkUnreads > 99
                ? 99
                : this.$store.getters.talkUnreads;
        }
    },
    methods: {
        sendLivestreamMessage(attendeeData: AttendeeDataObject) {
            this.livestreamMessageData = attendeeData;
            this.activeSidebar = "messages";
        },
        clearLivestreamMessageData() {
            this.livestreamMessageData = null;
        }
    }
});
